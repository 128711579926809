<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE ALIAS ITEM DATA / MATERIAL DATA</title>
    <section class="content-header">
      <h1>
        Update Alias Item Data
        <br />
        <h4>Please edit the item data alias</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Alias Item Details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Alias Item Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Alias Item Code</label>
                  <input
                    type="text"
                    v-model="kodebarangalias"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alias Item Code"
                  />
                  <input
                    type="hidden"
                    v-model="idxbarangalias"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Alias Item Name</label>
                  <input
                    type="text"
                    v-model="aliasbarang"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Alias Item Name"
                  />
                </div>
                <div class="row">
                  <div class="col-xs-9">
                    <label for="exampleInputEmail1">Supplier Code</label>
                    <input
                      type="text"
                      v-model="kodesupplier"
                      autocomplete="off"
                      class="form-control"
                      placeholder="Supplier Code"
                    />
                  </div>
                  <div class="col-xs-3">
                    <label for="exampleInputEmail1">Action</label>
                    <br />
                    <button
                      type="button"
                      @click="getsupplier()"
                      class="btn btn-info"
                    >
                      Search
                    </button>
                  </div>
                </div>
                <br />
                <div class="form-group">
                  <label for="exampleInputEmail1">Supplier Name</label>
                  <input
                    type="text"
                    readonly
                    v-model="namasupplier"
                    placeholder="Supplier Name"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                ><i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/barang">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "editbrgal",
  data() {
    return {
      loading: false,
      kodebarangalias: "",
      aliasbarang: "",
      kodesupplier: "",
      namasupplier: "",
      idxbarangalias: ""
    };
  },
  created() {
    this.loadData();
    // this.getsupplier()
    // console.log(this.kodesupplier);
  },
  methods: {
    getsupplier() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.kodesupplier;
      const urlAPIget =
        this.$apiurl +
        "supplier/getsupplierbykode?kode_supplier=" +
        this.kodesupplier;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Supplier Code not found",
              showConfirmButton: false
            });
          } else {
            this.namasupplier = resp.data.data.nama_supplier;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/getbarang_aliasbyid?id="+this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "barang_alias/getbarang_aliasbyid?id=" +
        this.$route.params.id;

      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp)
          this.kodebarangalias = resp.data.data.kode_barang_header;
          this.aliasbarang = resp.data.data.alias;
          this.kodesupplier = resp.data.data.kode_supplier;
          this.idxbarangalias = resp.data.data.id;
          // const urlAPIgetd = "http://26.183.23.191/inventory/backend/inventory/api/supplier/getsupplierbykode?kode_supplier=" + this.kodesupplier;
          const urlAPIgetd =
            this.$apiurl +
            "supplier/getsupplierbykode?kode_supplier=" +
            this.kodesupplier;
          axios
            .get(urlAPIgetd, { headers })
            .then((respd) => {
              this.namasupplier = respd.data.data.nama_supplier;
            })
            .catch((err) => {
              console.log(err);
            });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var kode = this.kodebarangalias;
      var nama = this.aliasbarang;
      var kodesup = this.kodesupplier;
      var idx = this.idxbarangalias;
      if (kode == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Code can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (nama == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Alias Item Name can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodesup == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Supplier can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          kode_barang_header: this.kodebarangalias,
          alias: this.aliasbarang,
          kode_supplier: this.kodesupplier,
          kode_user: kodeuser
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        // const urlAPIEdit = "http://26.183.23.191/inventory/backend/inventory/api/barang_alias/updatebarang_alias/"+ idx;
        const urlAPIEdit =
          this.$apiurl + "barang_alias/updatebarang_alias/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIEdit, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "barang" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
